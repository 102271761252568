<template>
  <v-container>
    <div class="botones-container">
      <v-row justify="space-between" align="center">
        <v-col cols="auto" v-for="boton in botonesEjecutivo" :key="boton.name">
          <v-btn
            @click="modal = true"
            v-if="boton.text === 'Enviar Email Aviso Deuda' && isEjecutivo"
            color="primary"
          >
            {{ boton.text }}
          </v-btn>
          <v-btn :to="boton.to" v-else color="primary">
            {{ boton.text }}
          </v-btn>
        </v-col>
      </v-row>

      <ModalListadoEmails
        :habilitar="modal"
        @update:habilitar="modal = $event"
      />
    </div>
  </v-container>
</template>
<script>
import ModalListadoEmails from '../components/ModalListadoEmails.vue';
export default {
  name: 'BotonesDashboard',
  components: {
    ModalListadoEmails,
  },
  data: () => ({
    isEjecutivo: false,
    modal: false,
    botonesEjecutivo: [
      { text: 'Tasa Variable', to: { name: 'TasaVariable' } },
      { text: 'Cuotas a Vencer', to: { name: 'Cuotas' } },
      { text: 'Importar Legajos', to: { name: 'ImportarLegajo' } },
      // {
      //   text: 'Importar Cuadros de marcha',
      //   to: { name: 'ImportarCuadroMarcha' },
      // },
      // {
      //   text: 'Importar Datos históricos',
      //   to: { name: 'ImportarDatosHistoricos' },
      // },
      {
        text: 'Enviar Email Aviso Deuda',
      },
      { text: 'Generar reportes', to: { name: 'GenerarReportes' } },
    ],
  }),
  created() {
    const rolId = parseInt(localStorage.getItem('rol'), 10);

    this.isEjecutivo = rolId === 6 ? true : false;
  },
};
</script>
<style>
.botones-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
