<template>
  <v-container v-cloak align-items="center" justify-content="center">
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row v-if="emailStatus !== 'confirmed'">
      <v-col>
        <v-alert color="error" dark icon="mdi-alert-circle-outline" prominent>
          Accede a tu correo para confirmar tu cuenta.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="isAdmin">
      <v-col>
        <Indicador
          modelo="unidadGestionClientes"
          titulo="Unidades de Gestión"
        ></Indicador>
      </v-col>
      <v-col>
        <Indicador modelo="servicioClientes" titulo="Solicitudes"></Indicador>
      </v-col>
      <v-col>
        <Indicador
          modelo="documentacionClientes"
          titulo="Documentos"
        ></Indicador>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row justify="space-between" v-if="isEjecutivo">
          <BotonesDashboard />
        </v-row>
        <v-row v-if="isEjecutivo" class="select-estado">
          <v-col cols="2">
            <v-text-field
              v-model="buscarCuit"
              label="Número de DNI/CUIT/CUIL"
              type="number"
              pattern="/^[0-9]{1,12}$/"
            >
            </v-text-field>
            <p v-if="buscarCuit.length > 11" style="color: red">
              Max. 11 caracteres
            </p>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              :loading="loading"
              dark
              @click="buscar(buscarCuit), resetComponent()"
            >
              Buscar
            </v-btn></v-col
          >
          <v-col md="2">
            <v-autocomplete
              v-if="isEjecutivo"
              @change="selectEstado"
              @input="resetComponent"
              v-model="values[0]"
              :items="isEjecutivo ? estadosEjecutivo : estados"
              clearable
              deletable-chips
              small-chips
              solo
              label="Seleccione Estado"
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-alert dense border="left" type="warning" v-if="mensaje !== ''">
          {{ mensaje }}
        </v-alert>
        <Liquidaciones v-if="isContable"></Liquidaciones>
        <Pendientes v-if="isOperador"></Pendientes>
        <ListaCreditos
          v-if="isEjecutivo && this.values[0] === null"
          :resultados="resultados"
        ></ListaCreditos>
        <Aprobados
          v-if="isEjecutivo && tipo === 6 && selectedComponent === 'Aprobados'"
          :isEjecutivo="isEjecutivo"
          :estado="6"
        ></Aprobados>
        <Otorgados
          v-if="isEjecutivo && tipo === 7 && selectedComponent === 'Otorgados'"
          :estado="7"
          :isEjecutivo="isEjecutivo"
        ></Otorgados>
        <Liquidacionesejecutivo
          v-if="
            isEjecutivo &&
            tipo === 0 &&
            selectedComponent === 'Liquidacionesejecutivo'
          "
        ></Liquidacionesejecutivo>
        <Auditar v-if="isAuditor"></Auditar>
        <Operatorias v-if="isAdmin"></Operatorias>
        <AnalisisLegal v-if="isLegal"></AnalisisLegal>
        <Revision v-if="isLegal" :legal="true"></Revision>
        <Revision v-if="isComite" :comite="true"></Revision>
        <Params v-if="isAdmin" clave="alta_legajo_credito_activado"></Params>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Indicador from '@/components/Dashboard/Indicador.vue';
import Pendientes from '@/components/Dashboard/Pendientes.vue';
import Operatorias from '@/components/Dashboard/Operatorias.vue';
import Revision from '@/components/Dashboard/Revision.vue';
import BotonesDashboard from '@/views/BotonesDashboard.vue';
import Aprobados from '@/components/Dashboard/Aprobados.vue';
import Auditar from '@/components/Dashboard/Auditar.vue';
import Otorgados from '@/components/Dashboard/Otorgados.vue';
import ListaCreditos from '@/components/Dashboard/ListaCreditos.vue';
import Regulares from '@/components/Dashboard/Regulares.vue';
import AnalisisLegal from '@/components/Dashboard/AnalisisLegal.vue';
import Params from '@/components/Dashboard/Params.vue';
import axios from 'axios';
import { API_URL } from '@/common/config';
import Liquidaciones from '../components/Dashboard/Liquidaciones.vue';
import Liquidacionesejecutivo from '@/components/Dashboard/Liquidacionesejecutivo.vue';
import { CancelToken } from 'axios';
import moment from 'moment';

export default {
  name: 'Dashboard',
  components: {
    Liquidacionesejecutivo,
    Auditar,
    Revision,
    Otorgados,
    Aprobados,
    Regulares,
    ListaCreditos,
    Indicador,
    Pendientes,
    Operatorias,
    AnalisisLegal,
    Liquidaciones,
    Params,
    BotonesDashboard,
  },
  data: () => ({
    buscarCuit: '',
    resultados: [],
    loading: false,
    flista: [], // Mis fideicomisos, es decir los que yo tengo asociados
    fideicomisos: [], // La lista de todos los fideicomisos disponibles
    isAdmin: false,
    isOperador: false,
    isLegal: false,
    isComite: false,
    isEjecutivo: false,
    isContable: false,
    isAuditor: false,
    estados: [],
    estadosEjecutivo: [],
    mensaje: '',
    tipo: -1,
    values: [null],
    selectedComponent: null,
    rolName: '',
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Dashboard',
        disabled: true,
        href: '/',
      },
    ],
    emailStatus: localStorage.getItem('emailStatus'),
    cancelToken: null,
  }),
  methods: {
    async limpiarMensaje() {
      setTimeout(async () => {
        this.mensaje = '';
      }, 3000);
    },
    async buscar(cuit) {
      try {
        if (!cuit || cuit.length < 5) {
          return;
        }
        this.values = [null];
        this.tipo = -1;
        this.buscarCuit = '';
        this.loading = true;
        this.resultados = [];
        const query = {
          method: 'POST',
          headers: {
            token: localStorage.getItem('token'),
          },
          url: `${API_URL}api/admin/servicioClientes/operatoria`,
          data: {
            cuit,
          },
        };
        const { data } = await axios(query);

        if (data.status === 'success') {
          this.resultados = data.lineaCreditoCliente.map((el) => ({
            id: el.lcc.lcc_id,
            dni: el.lcc.dni,
            cuit: el.lcc.cuit,
            //ADD new property
            dniCuit: el.lcc.dni ? el.lcc.dni : el.lcc.cuit,
            cliente_id: el.lcc.estado_id,
            cliente: `${el.lcc.apellido} ${el.lcc.nombre}`,
            razonSocial: el.lcc.razonSocial,
            //ADD new property
            clienteTable:
              el.lcc.apellido !== '' && el.lcc.nombre !== ''
                ? `${el.lcc.apellido} ${el.lcc.nombre}`
                : el.lcc.razonSocial,
            estado_id: el.lcc.estado_id,
            estado:
              el.trilay &&
              el.lcc.fecha_desembolso !== null &&
              parseInt(el.lcc.estado_id) === 7
                ? 'Enviar a Trilay'
                : parseInt(el.lcc.estado_id) === 7
                ? 'Otorgado'
                : 'Aprobado',
            // otorgado: el.lcc.updatedAt,
            otorgado:
              el.lcc.fecha_desembolso !== null ? el.lcc.fecha_desembolso : '',
            legajo: el.lcc.lcc_id,
            operatoria: el.lcc.lineaCredito_name,
          }));
          if (this.resultados.length === 0) {
            this.mensaje = 'No se encontraron resultados';
            this.limpiarMensaje();
          } else {
            this.mensaje = '';
          }
        } else {
          this.mensaje = 'No se encontraron resultados';
          this.limpiarMensaje();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    verCatalogo() {
      this.$router.push('/catalogo');
    },
    async getRol() {
      const rolid = localStorage.getItem('rol');
      const token = localStorage.getItem('token');
      const url = `${API_URL}api/roles/${rolid}`;
      const header = {
        headers: {
          token,
        },
      };
      console.log(header);
      await axios
        .get(url, header)
        .then((response) => {
          this.rolName = response.data.rol[0].nombre;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectEstado() {
      this.resultados = [];
      this.tipo = this.values[0];
      if (this.values[0] === 7) {
        this.selectedComponent = 'Otorgados';
      } else if (this.values[0] === 6) {
        this.selectedComponent = 'Aprobados';
      } else if (this.values[0] === 0) {
        this.selectedComponent = 'Liquidacionesejecutivo';
      }
    },
    getListaEstados() {
      this.estadosEjecutivo.push(
        { text: 'Créditos Aprobados', value: 6 },
        {
          text: 'Créditos para enviar a Trilay',
          value: 0,
        },
        { text: 'Créditos Otorgados', value: 7 }
      );
    },

    selectComponent(component) {
      this.selectedComponent = component;
    },
    resetComponent() {
      if (!this.values && this.values !== 0) {
        this.selectedComponent = 'Otorgados';
      }
    },
  },
  created() {
    this.getListaEstados();

    const rolId = parseInt(localStorage.getItem('rol'), 10);
    switch (rolId) {
      case 1:
        this.isAdmin = true;
        break;
      case 2:
        this.isOperador = true;
        break;
      case 4:
        this.isComite = true;
        break;
      case 5:
        this.isLegal = true;
        break;
      case 6:
        this.isEjecutivo = true;
        break;
      case 7:
        this.isContable = true;
        break;
      case 8:
        this.isAuditor = true;
        break;
      case 9:
        this.isOperador = true;
        break;
      default:
        break;
    }
  },
};
</script>
<style>
.v-input__slot {
  cursor: pointer !important;
  height: 40px;
  min-height: 40px !important;
  margin: 6px 0 0 0;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #1976d2 !important;
}
.v-select__slot span {
  border-radius: 4px !important;
  color: #ffff;
  font-size: 1rem;
  height: 30px !important;
  width: inherit !important;
}
.v-select__slot button {
  color: #ffff !important;
}
.select-estado {
  width: 100%;
}
.select-estado div {
  max-width: 400px !important;
  margin: 0 !important;
}
</style>
