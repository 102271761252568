<template>
  <v-row justify="center">
    <v-dialog v-model="habilitar" max-width="1500px" persistent>
      <h1>Legajos para notificar:</h1>
      <v-row>
        <v-col>
          <template>
            <div v-if="!flag">
              <v-col>
                <v-select
                  v-model="filtroFideicomiso"
                  :items="fideicomisos.map((el) => el.nombre)"
                  label="Fideicomiso"
                  @change="getOperatoria(filtroFideicomiso)"
                >
                  ></v-select
                >
              </v-col>
              <v-col>
                <v-select
                  v-model="filtroOperatoria"
                  :items="operatorias.map((el) => el.nombre)"
                  label="Operatoria"
                  @change="getLegajosANotificar(filtroOperatoria)"
                ></v-select>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
        </v-col>
      </v-row>

      <div class="my-modal-content">
        <v-alert v-if="success" border="bottom" color="success" dark>
          {{ successMensaje }} Total: {{ totalCreditos }}
        </v-alert>
        <v-alert v-if="error" border="bottom" color="red" dark>
          {{ errorMensaje }}
        </v-alert>
        <div v-if="isLoading" border="bottom" class="loading" dark>
          Cargando...
          <v-progress-circular
            v-if="isLoading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-alert v-if="bandera" border="bottom" color="info" dark>
                no hay email para enviar este mes!.
              </v-alert>

              <v-card>
                <v-text-field
                  v-model="filtroCuitDni"
                  label="Buscar por CUIT/DNI"
                  prepend-icon="mdi-magnify"
                  @keyup="filtrarTabla"
                  debounce="500"
                  clearable
                  v-if="flag"
                />
                <v-simple-table v-if="flag">
                  <thead>
                    <tr>
                      <th class="text-left">legajo</th>
                      <th class="text-left">CUIT/DNI</th>
                      <th class="text-left">Cliente</th>
                      <th class="text-left">Aviso</th>
                      <th class="text-left">Días</th>
                      <th class="pl-1">
                        <v-checkbox
                          label="Seleccionar Todos"
                          v-model="habilitarTodos"
                          @change="selectAll"
                          color="success"
                        >
                        </v-checkbox>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="index in tableData"
                      :key="index.lineacreditocliente_id"
                      :class="{ 'red-row': !index.correo }"
                    >
                      <td>
                        <v-text-field
                          v-model="index.lineacreditocliente_id"
                          label="Legajo"
                          readonly
                          :disabled="!index.email"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="index.cuit"
                          label="CUIT/DNI"
                          readonly
                          :disabled="!index.email"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="index.nombre"
                          readonly
                          :disabled="!index.email"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="index.aviso"
                          label="Asunto"
                          readonly
                          :disabled="!index.email"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="index.diasatraso"
                          label="Dias de Mora"
                          readonly
                          :disabled="!index.email"
                        ></v-text-field>
                      </td>

                      <td>
                        <v-row>
                          <v-checkbox
                            v-model="index.email"
                            :input-value="index.email"
                            :disabled="!index.correo"
                            color="success"
                            class="mr-20"
                          ></v-checkbox>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-card-actions class="justify-end">
                <v-btn class="mr-3 mb-2" color="primary" @click="guardar()">
                  <span v-if="!isLoading"> Guardar y enviar</span>

                  <v-progress-circular
                    v-if="isLoading"
                    :size="25"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </v-btn>
                <v-btn
                  @click="informe()"
                  class="mr-3 mb-2"
                  color="success"
                  left
                >
                  <span v-if="!isLoading"> Guardar excel </span>
                  <v-progress-circular
                    v-if="isLoading"
                    :size="25"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn rigth color="error" @click="cerrar()"> Salir </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';
import { showSimpleMessage, showConfirmacionMessage } from '@/common/util';
import xlsx from 'xlsx';
import moment from 'moment';

export default {
  name: 'ModalListadoEmails',
  props: ['habilitar'],
  data: () => ({
    tableData: [],
    totalCreditos: 0,
    filtroOperatoria: '',
    filtroFideicomiso: '',
    fideicomisosId: '',
    operatorias: [],
    fideicomisos: [],
    fechaInicio: '',
    fechaFinal: '',
    data: [],
    tableDataFiltrada: [],
    successMensaje: false,
    errorMensaje: false,
    success: false,
    error: false,
    isLoading: false,
    valid: false,
    bandera: false,
    flag: false,
    habilitarTodos: false,
    filtroCuitDni: [],
  }),
  methods: {
    getFideicomiso() {
      axios
        .get(`${API_URL}api/admin/unidadGestion`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.fideicomisos = response.data.fideicomisos;
        });
    },
    getOperatoria(fideicomisoFilt) {
      this.filtroNroTicket = '';
      const fideicomisos = this.fideicomisos.filter((el) => {
        if (el.nombre === `${fideicomisoFilt}`) return el.id;
      });
      this.fideicomisosId = fideicomisos[0].id ? fideicomisos[0].id : '';
      axios
        .get(
          `${API_URL}api/servicio/tipoServicioXfideicomiso/list/${this.fideicomisosId}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.operatorias = response.data.listado;
        });
      this.resetFilters;
    },
    filtrarTabla() {
      if (this.filtroCuitDni) {
        this.tableData = this.tableData.filter(
          (credito) =>
            credito.cuit &&
            credito.cuit
              .toLowerCase()
              .includes(this.filtroCuitDni.toLowerCase())
        );
      } else {
        this.tableData = this.data;
      }
    },
    async getLegajosANotificar(operatoria) {
      this.isLoading = true;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const tipoServicio = this.operatorias.filter((el) => {
        if (el.nombre === `${operatoria}`) return el.id;
      });

      const operatoriaId = tipoServicio[0].id;
      if (!operatoriaId) {
        return;
      }
      const url = `${API_URL}api/creditomora/${operatoriaId}`;

      await axios
        .get(url, header)
        .then((r) => {
          if (r.data.creditosEnMora.length > 0) {
            const filtCreditos = r.data.creditosEnMora.map((credito) => {
              if (credito.diasatraso >= 60 && credito.diasatraso < 90) {
                return { ...credito, aviso: '1° Aviso de deuda en mora.' };
              }
              if (credito.diasatraso >= 90 && credito.diasatraso < 120) {
                return { ...credito, aviso: '2° Aviso de deuda en mora.' };
              }
              if (credito.diasatraso >= 120) {
                return { ...credito, aviso: 'Último aviso Pre-Judicial.' };
              }
            });
            this.data = filtCreditos.filter((elem) => elem !== undefined);
            this.totalCreditos = r.data.total;
          } else {
            this.successMensaje = 'No hay creditos en mora para informar';
            this.errorMensaje = false;
            this.success = true;
            this.error = false;
            this.isLoading = false;
            this.totalCredito = 0;
            return showSimpleMessage(
              'Info',
              'No Hay créditos para informar',
              'info',
              2000
            );
          }
          this.flag = true;
          this.tableData = [];
          this.tableData = this.data;
          this.isLoading = false;
          this.success = true;
          this.successMensaje = 'Listado Generado';
        })
        .catch((e) => {
          this.error = true;
          this.errorMensaje = 'Ocurrió un problema al generar el listado';
          console.error(e);
          return showSimpleMessage(
            'Error',
            'Ocurrió un error al crear el estado',
            'error',
            2000
          );
        });
    },
    selectAll() {
      if (this.habilitarTodos) {
        this.tableData.forEach((t, i) => {
          this.tableData[i].email = true;
        });
      } else {
        this.tableData.forEach((t, i) => {
          this.tableData[i].email = false;
        });
      }
    },
    informe() {
      const columnsHands = [
        { title: 'Legajo', dataKey: 'lineacreditocliente_id' },
        { title: 'CUIT/DNI', dataKey: 'cuit' },
        { title: 'Cliente', dataKey: 'nombre' },
        { title: 'Aviso', dataKey: 'aviso' },
        { title: 'Días Mora', dataKey: 'diasatraso' },
        { title: 'Envio Email', dataKey: 'email' },
      ];
      const informes =
        this.tableData.length !== 0
          ? this.tableData.map((informe) => {
              const nuevoObjeto = {};
              columnsHands.forEach((columna) => {
                if (columna.dataKey === 'email') {
                  nuevoObjeto[columna.title] = informe[columna.dataKey]
                    ? 'Se envía aviso'
                    : 'No se envía aviso';
                } else {
                  nuevoObjeto[columna.title] = informe[columna.dataKey];
                }
              });
              return nuevoObjeto;
            })
          : [
              {
                Legajo: '',
                'CUIT/DNI': '',
                Cliente: '',
                Aviso: '',
                'Dias Mora': '',
                'Enviar Email': '',
              },
            ];
      const ws = xlsx.utils.json_to_sheet(informes);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'People');
      xlsx.writeFile(
        wb,
        `Email Avisos-${this.filtroOperatoria}-${moment().format(
          'DD/MM/YYYY'
        )}.xlsx`
      );
    },
    cerrar() {
      this.$emit('update:habilitar', false);
    },
    async guardar() {
      const tabla = this.tableData.some((legajos) => legajos.email === true);

      if (!tabla) {
        return showSimpleMessage(
          'Error',
          'No hay legajos seleccionados para notificar!',
          'info',
          2000
        );
      }
      const total = this.tableData.reduce((acc, legajo) => {
        return legajo.email === true ? acc + 1 : acc;
      }, 0);
      const confirma = await showConfirmacionMessage(
        '¿Desea confirmar?',
        `Se comenzará en envio de ${total} emails de aviso por deuda.`,
        'warning',
        true
      );
      this.$emit('update:habilitar', false);
      if (confirma) {
        const header = {
          headers: {
            token: localStorage.getItem('token'),
          },
        };
        const url = `${API_URL}api/sendemails`;

        axios
          .post(url, this.tableData, header)
          .then(() => {
            showSimpleMessage(
              'Finalizo el envio!',
              'Se enviaron los mails correpondientes',
              'success',
              2000
            );
          })
          .catch((error) => {
            console.error('Error al enviar los correos:', error);
            showSimpleMessage(
              'Error',
              'Ocurrió un error al enviar los correos. Por favor, inténtalo nuevamente.',
              'error',
              2000
            );
          })
          .finally(() => {
            this.cerrar();
          });
      }
      this.cerrar();
    },
  },
  watch: {
    habilitar() {
      this.habilitar === true
        ? (this.getFideicomiso(),
          ((this.totalCredito = 0),
          (this.flag = false),
          (this.success = false),
          (this.filtroOperatoria = ''),
          (this.filtroFideicomiso = ''),
          (this.fideicomisosId = ''),
          (this.totalCredito = 0)))
        : '';
    },
    filtroCuitDni(val) {
      this.filtrarTabla();
    },
  },
  created() {
    this.getFideicomiso();
  },
};
</script>
<style scoped>
.my-modal-content {
  width: 100%;
  background-color: rgb(201, 199, 199);
}
.v-dialog > * {
  width: 100%;
  background-color: rgb(201, 199, 199);
  padding: 20px;
}
.loading {
  background-color: rgb(201, 199, 199);
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.red-row {
  background-color: red;
}
</style>
